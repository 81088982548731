<template>
    <div class="flex flex-col px-52 w-full py-4 w-full h-full overflow-y-auto overflow-x-hidden">
        <div class="dark:bg-gray-800 w-full h-full relative mt-4 rounded-lg flex flex-row overflow-hidden">
            <form @submit.prevent="on_submit" class="w-96 min-w-96 max-w-96 flex flex-col bg-gray-700/50">

                <div class="p-4">
                    <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Name
                    </label>
                    <input
                        class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                        id="name" type="text" ref="name" v-model="name" />
                </div>
                <div class="px-4 pb-4">
                    <label for="video_url" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Video url
                    </label>
                    <input
                        class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                        id="video_url" type="text" ref="video_url" v-model="video_url" />
                </div>
                <div class="px-4 pb-4">
                    <label for="cnews-helper" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        CNews Helper
                    </label>
                    <div class="relative">
                        <input id="cnews-helper" type="text"
                            class="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            value="document.querySelectorAll('iframe.dailymotion-player')[1].src" disabled readonly>
                        <button
                            @click.prevent="cnews_helper_copy_clipboard('document.querySelectorAll(\'iframe.dailymotion-player\')[1].src')"
                            id="cnews-helper-button" type="button"
                            class="absolute end-2 top-1/2 -translate-y-1/2 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg p-2 inline-flex items-center justify-center">
                            <span id="default-icon-cnews-helper">
                                <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor" viewBox="0 0 18 20">
                                    <path
                                        d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                                </svg>
                            </span>
                            <span id="success-icon-cnews-helper" class="hidden inline-flex items-center">
                                <svg class="w-3.5 h-3.5 text-blue-700 dark:text-blue-500" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5" />
                                </svg>
                            </span>
                        </button>
                        <div id="tooltip-cnews-helper" role="tooltip"
                            class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                            <span id="default-tooltip-message-cnews-helper">Copy to clipboard</span>
                            <span id="success-tooltip-message-course-url" class="hidden">Copied!</span>
                            <div class="tooltip-arrow" data-popper-arrow></div>
                        </div>
                    </div>
                </div>

                <div class="border-b dark:border-gray-700"></div>
                <div class="px-4">
                    <button type="submit" :disabled="!video_url"
                        class="mt-4 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 flex flex-row items-center justify-center relative text-center"
                        :class="{ 'opacity-50 cursor-not-allowed': !video_url }">
                        <div class="flex">Scrape</div>
                        <svg aria-hidden="true" v-if="scrapping"
                            class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-900 fill-white absolute right-0 top-0 mt-2 me-2"
                            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor" />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill" />
                        </svg>
                    </button>
                </div>
            </form>
            <div class="w-full">
                <div class="relative">
                    <div class="p-4 flex flex-row items-center">
                        <div class="text-3xl text-gray-300 font-semibold flex-grow">
                            Scrapes
                        </div>
                    </div>
                    <ul
                        class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
                        <li class="me-2">
                            <a href="#" aria-current="page" @click.prevent="current_filter = 'scrapping'"
                                class="inline-block gap-2 flex flex-row items-center p-4 bg-gray-100 rounded-t-lg active dark:bg-gray-800"
                                :class="{
                                    'dark:text-blue-500 text-blue-600': current_filter === 'scrapping',
                                    'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300': current_filter !== 'scrapping'
                                }">
                                <div>Scrapping</div>
                                <div class="dark:bg-gray-700 px-2 rounded-md font-bold"
                                    v-if="$store.getters.getScrapesStatusCounts('scrapping') > 0">
                                    {{ $store.getters.getScrapesStatusCounts('scrapping') }}
                                </div>
                            </a>
                        </li>
                        <li class="me-2">
                            <a href="#" @click.prevent="current_filter = 'completed'"
                                class="inline-block gap-2 flex flex-row items-center p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800"
                                :class="{
                                    'dark:text-blue-500 text-blue-600': current_filter === 'completed',
                                    'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300': current_filter !== 'completed'
                                }">
                                <div>Completed</div>
                                <div class="dark:bg-gray-700 px-2 rounded-md font-bold"
                                    v-if="$store.getters.getScrapesStatusCounts('completed') > 0">
                                    {{ $store.getters.getScrapesStatusCounts('completed') }}
                                </div>
                            </a>
                        </li>
                        <li class="me-2">
                            <a href="#" @click.prevent="current_filter = 'failed'"
                                class="inline-block gap-2 flex flex-row items-center p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800"
                                :class="{
                                    'dark:text-blue-500 text-blue-600': current_filter === 'failed',
                                    'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300': current_filter !== 'failed'
                                }">
                                <div>Failed</div>
                                <div class="dark:bg-gray-700 px-2 rounded-md font-bold"
                                    v-if="$store.getters.getScrapesStatusCounts('failed') > 0">
                                    {{ $store.getters.getScrapesStatusCounts('failed') }}
                                </div>
                            </a>
                        </li>
                    </ul>
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th class="px-6 py-3">
                                    <div class="flex items-center">
                                        <input id="default-checkbox" type="checkbox" @click="selectAll"
                                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                        <svg class="w-6 h-6 text-gray-800 dark:text-white cursor-pointer "
                                            aria-hidden="true" data-dropdown-toggle="dropdown-actions-rows"
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                            viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2" d="m8 10 4 4 4-4" />
                                        </svg>
                                        <div id="dropdown-actions-rows"
                                            class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-[0_4px_8px_rgba(0,0,0,0.5)] w-44 dark:bg-gray-700">
                                            <ul class="py-2 text-sm text-gray-700 dark:text-gray-200"
                                                aria-labelledby="dropdownDefaultButton">
                                                <li v-if="current_filter == 'completed'">
                                                    <a href="#"
                                                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white ">
                                                        Transcribe selected
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#"
                                                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                        Delete selected
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    URL
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                v-for="scrape in scrapes" :key="scrape.id" v-if="scrapes && !loading_scrapes">
                                <td class="px-6 py-4">
                                    <div class="flex items-center mb-4">
                                        <input id="default-checkbox" type="checkbox" value=""
                                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                    </div>
                                </td>
                                <td class="px-6 py-4" :title="scrape.file_name">
                                    <div class="flex flex-row gap-2">
                                        <div>
                                            <img :src="get_scrape_thumbnail(scrape)" class="w-16 h-12 rounded-lg" />
                                        </div>
                                        <div>
                                            <div class="flex flex-col w-full">
                                                <div
                                                    class="font-medium text-gray-900 dark:text-gray-400 truncate w-96 flex flex-col">
                                                    <div>{{ scrape.name }}</div>
                                                    <a :href="scrape.video_url" class="text-xs dark:text-blue-500"
                                                        target="_blank">{{ scrape.video_url }}</a>
                                                </div>
                                                <div v-if="scrape.error"
                                                    class="text-sm text-red-500 dark:text-red-400 font-medium">
                                                    {{ scrape.error }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <div class="flex items-center gap-2">
                                        <button :disabled="scrape.status !== 'completed'" type="button"
                                            @click="transcribe(scrape)"
                                            :class="{ 'opacity-50 cursor-not-allowed': scrape.status !== 'completed' }"
                                            class="px-3 py-2 text-xs text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 flex flex-row items-center justify-between font-semibold">
                                            <div>Transcribe</div>
                                        </button>
                                        <a :href="get_download_url(scrape)" target="_blank" :disabled="scrape.status !== 'completed'" type="button"
                                            :class="{ 'opacity-50 cursor-not-allowed': scrape.status !== 'completed' }"
                                            class="px-3 py-2 text-xs text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 flex flex-row items-center justify-between font-semibold">
                                            <div>Download</div>
                                        </a>
                                        <button type="button"
                                            class="px-3 py-2 text-xs font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 font-semibold"
                                            @click="deleteScrape(scrape)">
                                            Delete
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="loading_scrapes">
                                <td class="px-6 py-4 whitespace-nowrap" colspan="4">
                                    <div class="flex justify-center">
                                        <div class="text-sm font-medium text-gray-900 dark:text-gray-400">
                                            Loading...
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="scrapes.length === 0 && !loading_scrapes">
                                <td class="px-6 py-4 whitespace-nowrap" colspan="4">
                                    <div class="flex justify-center">
                                        <div class="text-sm font-medium text-gray-900 dark:text-gray-400">
                                            No video found
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Pagination :options="$store.getters.getScrapesPagination" @update:options="on_pagination" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from '@/components/pagination.vue';
import Utils from '@/utils';
import { Dropdown, initFlowbite } from 'flowbite'

export default {
    name: 'Scrapes',
    components: {
        Pagination
    },
    data() {
        return {
            loading_scrapes: true,
            scrapping: false,
            name: '',
            video_url: '',
            limit: 10,
            page: 1,
            error: null,
            current_filter: 'scrapping',
            cnews_helper: null
        }
    },
    methods: {
        ...Utils,
        get_download_url(scrape) {
            return `${this.$store.getters.getApiBase}/scrapes/${scrape._id}/download`
        },
        async on_submit() {
            this.scrapping = true;
            const response = await this.$store.dispatch('createScrape', {
                video_url: this.video_url,
                name: this.name
            });
            this.scrapping = false;

            if (response.success) {
                if (this.current_filter === response.data.status) {
                    await this.$store.dispatch('appendScrape', response.data);
                }

                await this.$store.dispatch('incrementScrapesCount', {
                    status: response.data.status,
                    count: 1
                })

                this.$refs.file.value = '';
            }
            else {
                this.error = response.error;
            }
        },
        selectAll(event) {
            const checkboxes = document.querySelectorAll('input[type="checkbox"]');
            checkboxes.forEach((checkbox) => {
                checkbox.checked = !checkbox.checked;
            });
            event.target.checked = !event.target.checked;
        },
    
        async deleteScrape(scrape) {
            const response = await this.$store.dispatch('deleteScrape', scrape._id);
            if (response.success) {
                await this.$store.dispatch('fetchScrapes');
            }
        },
        async on_pagination(options) {
            this.loading = true;
            options.status = this.current_filter;
            this.limit = options.limit;
            this.page = options.page;
            await this.$store.dispatch('fetchScrapes', options);
            this.loading = false;
        },
        transcribe(scrape) {
            this.$router.push({ name: 'transcriptions', query: { scrape_id: scrape._id } });
        },
        cnews_helper_copy_clipboard(text) {
            navigator.clipboard.writeText(text).then(function () {
            }, function () {
            });
        },
        get_scrape_thumbnail(scrape) {
            if (!scrape.thumbnail_path) return '';
            return process.env.VUE_APP_API_BASE + '/' + scrape.thumbnail_path.replace('scrapes/', 'thumbnails/') || 'https://via.placeholder.com/150';
        }
    },
    sockets: {
        async scrape(scrape) {

            if (scrape.status == 'failed') {
                this.$store.dispatch('incrementScrapesCount', {
                    status: 'scrapping',
                    count: -1
                })

                this.$store.dispatch('incrementScrapesCount', {
                    status: 'failed',
                    count: 1
                })
            }
            else if (scrape.status == 'completed') {
                this.$store.dispatch('incrementScrapesCount', {
                    status: 'scrapping',
                    count: -1
                })

                this.$store.dispatch('incrementScrapesCount', {
                    status: 'completed',
                    count: 1
                })
            }

            if (this.current_filter != scrape.status) {
                this.$store.commit('removeScrape', scrape._id);
            }
            else {
                const exists = this.$store.getters.getScrape(scrape._id);
                this.$store.dispatch(exists ? 'updateScrape' : 'appendScrape', scrape);
            }
        },

    },
    computed: {
        scrapes() {
            return this.$store.getters.getScrapes;
        },
    },
    async mounted() {
        this.loading_scrapes = true;
        await this.$store.dispatch('fetchScrapes', {
            limit: this.limit,
            page: this.page,
            status: this.current_filter
        });
        this.loading_scrapes = false;

        this.$nextTick(() => {
            initFlowbite();
        });
    },
    watch: {
        current_filter: {
            handler: async function (val) {
                this.loading_scrapes = true;
                await this.$store.dispatch('fetchScrapes', {
                    limit: this.limit,
                    page: this.page,
                    status: val
                });
                this.loading_scrapes = false;

                this.$nextTick(() => {
                    initFlowbite();
                });
            },
            immediate: true
        },
    }
}
</script>
