import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

import Home from '@/views/home.vue'
import Monitor from '@/views/monitor.vue'
import MonitorRun from '@/views/monitor_run.vue'
import Variables from '@/views/variables.vue'
import Prompts from '@/views/prompts.vue'
import Solver from '@/views/solver.vue'
import Transcriptions from '@/views/transcriptions.vue'
import Scrapes from '@/views/scrapes.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { requiresAuth: true },
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    meta: { requiresAuth: false },
    component: () => import('@/views/login.vue')
  },
  {
    path: '/solver',
    name: 'solver',
    meta: { requiresAuth: true },
    component: Solver
  },
  {
    path: '/scrapping',
    name: 'scrapping',
    meta: { requiresAuth: true },
    component: Scrapes
  },
  {
    path: '/transcriptions',
    name: 'transcriptions',
    meta: { requiresAuth: true },
    component: Transcriptions
  },
  {
    path: '/:chatId/monitor/:runId',
    name: 'monitor_run',
    meta: { requiresAuth: true },
    component: MonitorRun
  },
  {
    path: '/:chatId/monitor',
    name: 'monitor',
    component: Monitor,
    meta: { requiresAuth: true },
    meta: { transition: 'slide-left' },
  },
  {
    path: '/:chatId/variables',
    name: 'variables',
    component: Variables,
    meta: { requiresAuth: true },
    meta: { transition: 'slide-left' },
  },
  {
    path: '/:chatId/prompts',
    name: 'prompts',
    component: Prompts,
    meta: { requiresAuth: true },
    meta: { transition: 'slide-left' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//   if (store.getters.isAuthenticated) {
//     if (to.name === 'login' || to.name === 'recover') {
//       next({ name: 'home' });
//     } else {
//       next();
//     }
//   } else {
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//       next({ name: 'login' });
//     } else {
//       next();
//     }
//   }
// });

export default router
