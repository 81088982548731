<template>
    <div class="flex flex-col px-52 w-full py-4 w-full h-full overflow-y-auto overflow-x-hidden">
        <div class="dark:bg-gray-800 w-full h-full relative mt-4 rounded-lg flex flex-row overflow-hidden">
            <form @submit.prevent="on_submit"
                class="w-96 min-w-96 max-w-96 flex flex-col bg-gray-800 relative border-r border-gray-700 shadow-[4px_0_4px_rgba(0,0,0,0.5)] z-10">
                <div class="options h-[calc(100vh-160px)]">
                    <div class="flex my-4 flex-col px-4">
                        <label for="from_scrapes" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            From scrapping
                        </label>
                        <div class="relative">
                            <div id="scrapes" data-dropdown-toggle="dropdown-scrapes" data-dropdown-offset-distance="2"
                                class="cursor-pointer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 flex flex-row items-center">
                                <div class="basis-11/12 flex flex-row gap-2">
                                    <img :src="get_scrape_thumbnail(selected_scrape)" v-if="selected_scrape"
                                        class="h-[42px] w-[60px] min-w-[60px] object-cover object-cover" />
                                    <div class="line-clamp-1">
                                        {{ selected_scrape?.name || 'Select a scrape' }}
                                    </div>
                                </div>
                                <div class="ms-3 basis-1/12">
                                    <svg class="w-2.5 h-2.5 min-w-2.5 min-h-2.5 max-w-2.5 max-h-2.5 " aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </div>
                            </div>
                            <div id="dropdown-scrapes"
                                class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-[0_4px_8px_rgba(0,0,0,0.5)] w-96 dark:bg-gray-700">
                                <ul class="py-2 text-sm text-gray-700 dark:text-gray-200"
                                    aria-labelledby="from_scrapes">
                                    <li v-for="scrape in scrapes">
                                        <a href="#" @click.prevent="select_scrape(scrape._id)"
                                            :data-tooltip-target="`tooltip-scrapes-${scrape._id}`"
                                            data-tooltip-placement="right-start"
                                            class=" px-4 py-1 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white line-clamp-3">
                                            <div class="flex flex-row gap-2">
                                               <div><img :src="get_scrape_thumbnail(scrape)" v-if="scrape.thumbnail_path"
                                                class="h-[42px] w-[60px] min-w-[60px] object-cover" /></div>
                                                <div>
                                                    <div>{{ scrape.name }}</div>
                                                    <div class="text-xs text-gray-400">{{ scrape.video_url }}</div>
                                                </div>
                                            </div>
                                        </a>
                                        <div :id="`tooltip-scrapes-${scrape._id}`" role="tooltip"
                                            class="shadow-[0_4px_8px_rgba(0,0,0,0.5)] absolute z-50 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                            {{ scrape.video_url }}
                                            <div class="tooltip-arrow" data-popper-arrow></div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="border-b dark:border-gray-700"></div>
                    <div class="p-4">
                        <label for="file_input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Choose a file
                        </label>
                        <input
                            class="block w-full text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                            id="file_input" type="file" ref="file" @change="on_file_changed" />
                    </div>
                    <div class="flex flex-col">
                        <div class="flex mb-4 flex-col px-4">
                            <label for="speech_models"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Speech model
                            </label>
                            <div class="relative">
                                <div id="speech_models" data-dropdown-toggle="dropdown-speech-models"
                                    data-dropdown-offset-distance="2"
                                    class="cursor-pointer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 flex flex-row items-center">
                                    <div class="flex-grow">
                                        {{ selected_speech_model.label }}
                                    </div>
                                    <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </div>
                                <div id="dropdown-speech-models"
                                    class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-[0_4px_8px_rgba(0,0,0,0.5)] w-full dark:bg-gray-700">
                                    <ul class="py-2 text-sm text-gray-700 dark:text-gray-200"
                                        aria-labelledby="dropdownDefaultButton">
                                        <li v-for="model in speech_models">
                                            <a href="#" @click.prevent="select_speech_model(model.name)"
                                                :data-tooltip-target="`tooltip-speech-model-${model.name}`"
                                                data-tooltip-placement="right-start"
                                                class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                {{ model.label }}
                                            </a>
                                            <div :id="`tooltip-speech-model-${model.name}`" role="tooltip"
                                                class="shadow-[0_4px_8px_rgba(0,0,0,0.5)] absolute z-50 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                                {{ model.help }}
                                                <div class="tooltip-arrow" data-popper-arrow></div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="border-b dark:border-gray-700 mb-4"></div>
                        <div class="flex items-center mb-4 px-4">
                            <input id="option-language-detection" type="checkbox" v-model="options.language_detection"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="option-language-detection"
                                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 flex flex-row items-center w-full">
                                <div class="flex-grow">Language detection</div>
                                <div :class="{ 'rotate-180': options.language_detection }">
                                    <svg class="w-2.5 h-2.5 min-w-2.5 min-h-2.5 max-w-2.5 max-h-2.5 " aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </div>
                            </label>
                        </div>
                        <div class="flex mb-4 flex-col px-4" v-show="options.language_detection">
                            <label for="language_codes"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Language
                            </label>
                            <select id="language_codes" v-model="options.language_code"
                                :disabled="options.language_detection"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="en">Global english</option>
                                <option value="en_au">Australian English</option>
                                <option value="en_uk">British English</option>
                                <option value="en_us">US English</option>
                                <option value="es">Spanish</option>
                                <option value="fr">French</option>
                                <option value="de">German</option>
                                <option value="it">Italian</option>
                                <option value="pt">Portuguese</option>
                                <option value="nl">Dutch</option>
                                <option value="hi">Hindi</option>
                                <option value="ja">Japanese</option>
                                <option value="zh">Chinese</option>
                                <option value="fi">Finnish</option>
                            </select>
                        </div>
                        <div class="border-b dark:border-gray-700 mb-4"></div>
                        <div class="flex items-center mb-4 px-4">
                            <input id="option-summarization" type="checkbox" v-model="options.summarization"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="option-summarization"
                                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 flex items-center w-full   ">
                                <div class="flex-grow">Summarization</div>
                                <div :class="{ 'rotate-180': options.summarization }">
                                    <svg class="w-2.5 h-2.5 min-w-2.5 min-h-2.5 max-w-2.5 max-h-2.5 " aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </div>
                            </label>
                        </div>
                        <div class="flex mb-4 flex-col px-4" v-show="options.summarization">
                            <label for="summary_models"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Summary model
                            </label>
                            <div class="relative">
                                <div id="summary_models" data-dropdown-toggle="dropdown-summary-models"
                                    data-dropdown-offset-distance="2"
                                    class="cursor-pointer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 flex flex-row items-center">
                                    <div class="flex-grow">
                                        {{ selected_summary_model.label }}
                                    </div>
                                    <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </div>
                                <div id="dropdown-summary-models"
                                    class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-[0_4px_8px_rgba(0,0,0,0.5)] w-full dark:bg-gray-700">
                                    <ul class="py-2 text-sm text-gray-700 dark:text-gray-200"
                                        aria-labelledby="dropdownDefaultButton">
                                        <li v-for="model in summary_models">
                                            <a href="#" @click.prevent="select_summary_model(model.name)"
                                                :data-tooltip-target="`tooltip-summary-model-${model.name}`"
                                                data-tooltip-placement="right-start"
                                                class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                {{ model.label }}
                                            </a>
                                            <div :id="`tooltip-summary-model-${model.name}`" role="tooltip"
                                                class="shadow-[0_4px_8px_rgba(0,0,0,0.5)] absolute z-50 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                                {{ model.help }}
                                                <div class="tooltip-arrow" data-popper-arrow></div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="flex mb-4 flex-col px-4" v-show="options.summarization">
                            <label for="summary_types"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Summary type
                            </label>
                            <div class="relative">
                                <div id="summary_types" data-dropdown-toggle="dropdown-summary-types"
                                    data-dropdown-offset-distance="2"
                                    class="cursor-pointer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 flex flex-row items-center">
                                    <div class="flex-grow">
                                        {{ selected_summary_type.label }}
                                    </div>
                                    <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </div>
                                <div id="dropdown-summary-types"
                                    class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-[0_4px_8px_rgba(0,0,0,0.5)] w-full dark:bg-gray-700">
                                    <ul class="py-2 text-sm text-gray-700 dark:text-gray-200"
                                        aria-labelledby="dropdownDefaultButton">
                                        <li v-for="type in summary_types">
                                            <a href="#" @click.prevent="select_summary_type(type.name)"
                                                :data-tooltip-target="`tooltip-summary-type-${type.name}`"
                                                data-tooltip-placement="right-start"
                                                class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                {{ type.label }}
                                            </a>
                                            <div :id="`tooltip-summary-type-${type.name}`" role="tooltip"
                                                class="shadow-[0_4px_8px_rgba(0,0,0,0.5)] absolute z-50 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                                {{ type.help }}
                                                <div class="tooltip-arrow" data-popper-arrow></div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="border-b dark:border-gray-700 mb-4"></div>
                        <div class="grid grid-cols-2">
                            <div class="flex items-center mb-4 px-4 ">
                                <input id="option-auto-chapters" type="checkbox" v-model="options.auto_chapters"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                <label for="option-auto-chapters"
                                    class="ms-2 text-[13px] font-medium text-gray-900 dark:text-gray-300 whitespace-nowrap">Auto
                                    chapters</label>
                            </div>
                            <div class="flex items-center mb-4 px-4">
                                <input id="option-speakers-labels" type="checkbox" v-model="options.speaker_labels"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                <label for="option-speakers-labels"
                                    class="ms-2 text-[13px] font-medium text-gray-900 dark:text-gray-300 whitespace-nowrap">Speaker
                                    labels</label>
                            </div>
                            <div class="flex items-center mb-4 px-4">
                                <input id="option-punctuate" type="checkbox" v-model="options.punctuate"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                <label for="option-punctuate"
                                    class="ms-2 text-[13px] font-medium text-gray-900 dark:text-gray-300 whitespace-nowrap">Punctuate</label>
                            </div>
                            <div class="flex items-center mb-4 px-4">
                                <input id="option-format-text" type="checkbox" v-model="options.format_text"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                <label for="option-format-text"
                                    class="ms-2 text-[13px] font-medium text-gray-900 dark:text-gray-300 whitespace-nowrap ">Format
                                    text</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="absolute bottom-4 left-0 right-0">
                    <div class="flex flex-row items-center justify-between mt-4" v-if="error">
                        <div class="text-red-500 text-sm font-medium">
                            {{ error }}
                        </div>
                    </div>
                    <div class="px-4 pb-4dark:bg-gray-700 border-t dark:border-gray-700">
                        <button type="submit" :disabled="(!has_file && !options.scrape_id)"
                            class="mt-4 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 flex flex-row items-center justify-center relative text-center"
                            :class="{ 'opacity-50 cursor-not-allowed': (!has_file && !options.scrape_id) }">
                            <div class="flex">Upload file</div>
                            <svg aria-hidden="true" v-if="uploading"
                                class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-900 fill-white absolute right-0 top-0 mt-2 me-2"
                                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor" />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill" />
                            </svg>
                        </button>
                    </div>
                </div>
            </form>
            <div class="w-full z-0">
                <div class="relative">
                    <div class="p-4 flex flex-row items-center">
                        <div class="text-3xl text-gray-300 font-semibold flex-grow">
                            Transcriptions
                        </div>
                        <div class="flex flex-row items-center gap-2">
                            <div class="text-gray-500 font-semibold">
                                Balance
                            </div>
                            <div class="relative">
                                <div class="text-gray-300 font-semibold" v-if="$store.getters.getTranscriptionsBalance">
                                    {{ formatPrice($store.getters.getTranscriptionsBalance) }}
                                </div>
                                <div v-else class="text-gray-300 font-semibold">-</div>
                            </div>
                            <div>
                                <svg class="w-4 h-4 text-gray-800 dark:text-gray-400 cursor-pointer hover:dark:text-white"
                                    :class="{ 'animate-spin': loading_balance }" aria-hidden="true"
                                    @click="refresh_balance" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <ul
                        class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
                        <li class="me-2">
                            <a href="#" aria-current="page" @click.prevent="current_filter = 'transcribing'"
                                class="inline-block gap-2 flex flex-row items-center p-4 bg-gray-100 rounded-t-lg active dark:bg-gray-800"
                                :class="{
                                    'dark:text-blue-500 text-blue-600': current_filter === 'transcribing',
                                    'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300': current_filter !== 'transcribing'
                                }">
                                <div>Transcribing</div>
                                <div class="dark:bg-gray-700 px-2 rounded-md font-bold"
                                    v-if="$store.getters.getTranscriptionsStatusCounts('transcribing') > 0">
                                    {{ $store.getters.getTranscriptionsStatusCounts('transcribing') }}
                                </div>
                            </a>
                        </li>
                        <li class="me-2">
                            <a href="#" @click.prevent="current_filter = 'completed'"
                                class="inline-block gap-2 flex flex-row items-center p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800"
                                :class="{
                                    'dark:text-blue-500 text-blue-600': current_filter === 'completed',
                                    'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300': current_filter !== 'completed'
                                }">
                                <div>Completed</div>
                                <div class="dark:bg-gray-700 px-2 rounded-md font-bold"
                                    v-if="$store.getters.getTranscriptionsStatusCounts('completed') > 0">
                                    {{ $store.getters.getTranscriptionsStatusCounts('completed') }}
                                </div>
                            </a>
                        </li>
                        <li class="me-2">
                            <a href="#" @click.prevent="current_filter = 'failed'"
                                class="inline-block gap-2 flex flex-row items-center p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800"
                                :class="{
                                    'dark:text-blue-500 text-blue-600': current_filter === 'failed',
                                    'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300': current_filter !== 'failed'
                                }">
                                <div>Failed</div>
                                <div class="dark:bg-gray-700 px-2 rounded-md font-bold"
                                    v-if="$store.getters.getTranscriptionsStatusCounts('failed') > 0">
                                    {{ $store.getters.getTranscriptionsStatusCounts('failed') }}
                                </div>
                            </a>
                        </li>
                    </ul>
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 z-0">
                        <thead
                            class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 shadow-[4px_0_4px_rgba(0,0,0,0.5)]">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    File name
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Confidence
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                v-for="transcription in transcriptions" :key="transcription.id"
                                v-if="transcriptions && !loading_transcriptions">
                                <td class="px-6 py-4" :title="transcription.file_name">
                                    <div class="flex flex-col w-full">
                                        <div class="font-medium text-gray-900 dark:text-gray-400 truncate w-96">
                                            {{ transcription.file_name }}
                                        </div>
                                        <div v-if="transcription.error"
                                            class="text-sm text-red-500 dark:text-red-400 font-medium">
                                            {{ transcription.error }}
                                        </div>
                                    </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <div class="flex">
                                        <div class="text-sm font-medium text-gray-900 dark:text-gray-400">
                                            {{ transcription?.confidence }}
                                        </div>
                                    </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <div class="flex items-center gap-2">

                                        <button :disabled="transcription.status !== 'completed'"
                                            :data-dropdown-toggle="`dropdown-download-options-${transcription._id}`"
                                            type="button"
                                            :class="{ 'opacity-50 cursor-not-allowed': transcription.status !== 'completed' }"
                                            class="px-3 py-2 text-xs text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 flex flex-row items-center justify-between font-semibold">
                                            <div>Download</div>
                                            <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" stroke-linecap="round"
                                                    stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                                            </svg>
                                        </button>
                                        <div :id="`dropdown-download-options-${transcription._id}`"
                                            class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                                            <ul class="py-2 text-sm text-gray-700 dark:text-gray-200"
                                                aria-labelledby="dropdownInformationButton">
                                                <li v-if=transcription.text>
                                                    <a href="#"
                                                        @click.prevent="downloadTranscription(transcription, 'text')"
                                                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                        Text
                                                    </a>
                                                </li>
                                                <li v-if="transcription.summary">
                                                    <a href="#"
                                                        @click.prevent="downloadTranscription(transcription, 'summary')"
                                                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                        Summary
                                                    </a>
                                                </li>
                                                <li v-if="transcription.chapters?.length > 0">
                                                    <a href="#"
                                                        @click.prevent="downloadTranscription(transcription, 'chapters')"
                                                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                        Chapters
                                                    </a>
                                                </li>
                                                <li v-if="transcription.utterances?.length > 0">
                                                    <a href="#"
                                                        @click.prevent="downloadTranscription(transcription, 'dialogue')"
                                                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                        Dialogue
                                                    </a>
                                                </li>
                                                <li v-if="transcription.utterances?.length > 0">
                                                    <a href="#"
                                                        @click.prevent="downloadTranscription(transcription, 'srt')"
                                                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                                        SRT
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <button type="button"
                                            class="px-3 py-2 text-xs font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 font-semibold"
                                            @click="deleteTranscription(transcription)">
                                            Delete
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="loading_transcriptions">
                                <td class="px-6 py-4 whitespace-nowrap" colspan="4">
                                    <div class="flex justify-center">
                                        <div class="text-sm font-medium text-gray-900 dark:text-gray-400">
                                            Loading...
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="transcriptions.length === 0 && !loading_transcriptions">
                                <td class="px-6 py-4 whitespace-nowrap" colspan="4">
                                    <div class="flex justify-center">
                                        <div class="text-sm font-medium text-gray-900 dark:text-gray-400">
                                            No transcriptions found
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Pagination :options="$store.getters.getTranscriptionsPagination" @update:options="on_pagination" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from '@/components/pagination.vue';
import Utils from '@/utils';
import { Dropdown, initFlowbite } from 'flowbite'

export default {
    name: 'Transcriptions',
    components: {
        Pagination
    },
    data() {
        return {
            has_file: false,
            loading_transcriptions: true,
            loading_balance: true,
            uploading: false,
            limit: 10,
            page: 1,
            error: null,
            showModalSettings: false,
            current_filter: 'transcribing',
            speech_models: [
                { name: 'nano', label: 'Nano', help: 'A lightweight, lower cost model for a wide range of languages.' },
                { name: 'best', label: 'Best', help: 'The best model for a wide range of languages.' },
            ],
            summary_models: [
                { name: 'informative', label: 'Informative', help: 'Best for files with a single speaker such as presentations or lectures.' },
                { name: 'conversational', label: 'Conversational', help: 'Best for any 2 person conversation such as customer/agent or interview/interviewee calls.' },
                { name: 'catchy', label: 'Catchy', help: 'Best for creating video, podcast, or media titles.' },
            ],
            summary_types: [
                { name: 'bullets', label: 'Bullets', help: 'A bulleted summary with the most important points.' },
                { name: 'bullets_verbose', label: 'Bullets verbose', help: 'A longer bullet point list summarizing the entire transcription text.' },
                { name: 'gist', label: 'Gist', help: 'A few words summarizing the entire transcription text.' },
                { name: 'headline', label: 'Headline', help: 'A single sentence summarizing the entire transcription text.' },
                { name: 'paragraph', label: 'Paragraph', help: 'A single paragraph summarizing the entire transcription text.' },
            ],
            options: {
                scrape_id: null,
                summarization: false,
                summary_model: 'informative',
                summary_type: 'bullets',
                auto_chapters: false,
                speaker_labels: false,
                punctuate: false,
                format_text: false,
                speech_model: 'nano',
                language_detection: true,
                language_code: "en",
                disfluencies: false,
                filter_profanity: false,
            }
        }
    },
    methods: {
        ...Utils,
        async on_submit() {
            this.uploading = true;
            this.has_file = false;

            const response = await this.$store.dispatch('uploadTranscription', {
                file: this.$refs.file.files[0],
                options: this.options,
                progress_cb: (progress) => {
                    console.log(progress)
                }
            });

            this.uploading = false;

            if (response.success) {

                if (this.current_filter === response.data.status) {
                    await this.$store.dispatch('appendTranscription', response.data);
                }

                await this.$store.dispatch('incrementTranscriptionsCount', {
                    status: response.data.status,
                    count: 1
                })

                this.$refs.file.value = '';
            }
            else {
                this.error = response.error;
            }
        },
        async downloadTranscription(transcription, type) {
            const response = await this.$store.dispatch('downloadTranscription', {
                id: transcription._id,
                type
            });
            const text = await response.text();

            const url = window.URL.createObjectURL(new Blob([text]));
            const link = document.createElement('a');
            link.href = url;
            const without_extension = transcription.file_name.split('.').slice(0, -1).join('.');

            let filename = '';

            if (type == 'srt')
                filename = `${without_extension}.srt`;
            else if (type == 'dialogue')
                filename = `${without_extension}_${type}.txt`;
            else if (type == 'chapters')
                filename = `${without_extension}_${type}.txt`;
            else
                filename = `${without_extension}_${type}.txt`;

            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        },
        async deleteTranscription(transcription) {
            const response = await this.$store.dispatch('deleteTranscription', transcription._id);
            if (response.success) {
                await this.$store.dispatch('fetchTranscriptions');
            }
        },
        async on_pagination(options) {
            this.loading = true;
            options.status = this.current_filter;
            this.limit = options.limit;
            this.page = options.page;
            await this.$store.dispatch('fetchTranscriptions', options);
            this.loading = false;
        },
        async refresh_balance() {
            this.loading_balance = true;
            await this.$store.dispatch('fetchTranscriptionsBalance');
            this.loading_balance = false;
        },
        on_file_changed() {
            const file = this.$refs.file.files[0];
            this.has_file = !!file;
        },
        select_speech_model(model) {
            this.options.speech_model = model;
            const $targetEl = document.getElementById('dropdown-speech-models')
            const $triggerEl = document.getElementById('speech_models');
            const dropdown = new Dropdown($targetEl, $triggerEl, {
                offsetDistance: 2,
            }, {
                override: true,
                id: 'dropdown-speech-models'
            });

            dropdown.hide();
        },
        select_scrape(id) {
            this.options.scrape_id = id;
            const $targetEl = document.getElementById('dropdown-scrapes')
            const $triggerEl = document.getElementById('scrapes');
            const dropdown = new Dropdown($targetEl, $triggerEl, {
                offsetDistance: 2,
            }, {
                override: true,
                id: 'dropdown-scrapes'
            });

            dropdown.hide();
        },
        select_summary_model(model) {
            this.options.summary_model = model;
            const $targetEl = document.getElementById('dropdown-summary-models')
            const $triggerEl = document.getElementById('summary_models');
            const dropdown = new Dropdown($targetEl, $triggerEl, {
                offsetDistance: 2,
            }, {
                override: true,
                id: 'dropdown-summary-models'
            });

            dropdown.hide();
        },
        select_summary_type(type) {
            this.options.summary_type = type;
            const $targetEl = document.getElementById('dropdown-summary-types')
            const $triggerEl = document.getElementById('summary_types');
            const dropdown = new Dropdown($targetEl, $triggerEl, {
                offsetDistance: 2,
            }, {
                override: true,
                id: 'dropdown-summary-types'
            });

            dropdown.hide();
        },
        get_scrape_thumbnail(scrape) {
            if (!scrape.thumbnail_path) return '';
            return process.env.VUE_APP_API_BASE + '/' + scrape.thumbnail_path.replace('scrapes/', 'thumbnails/') || 'https://via.placeholder.com/150';
        }
    },
    sockets: {
        async transcription(transcription) {
            if (transcription.status == 'failed') {
                this.$store.dispatch('incrementTranscriptionsCount', {
                    status: 'transcribing',
                    count: -1
                })

                this.$store.dispatch('incrementTranscriptionsCount', {
                    status: 'failed',
                    count: 1
                })
            }
            else if (transcription.status == 'completed') {
                this.$store.dispatch('incrementTranscriptionsCount', {
                    status: 'transcribing',
                    count: -1
                })

                this.$store.dispatch('incrementTranscriptionsCount', {
                    status: 'completed',
                    count: 1
                })
            }

            if (transcription.status === 'completed')
                await this.refresh_balance();

            if (this.current_filter != transcription.status) {
                this.$store.commit('removeTranscription', transcription._id);
            }
            else {
                const exists = this.$store.getters.getTranscription(transcription._id);
                this.$store.dispatch(exists ? 'updateTranscription' : 'appendTranscription', transcription);
            }
        }
    },
    computed: {
        scrapes() {
            return this.$store.getters.getScrapes;
        },
        transcriptions() {
            return this.$store.getters.getTranscriptions;
        },
        selected_speech_model() {
            return this.speech_models.find(model => model.name === this.options.speech_model);
        },
        selected_summary_model() {
            return this.summary_models.find(model => model.name === this.options.summary_model);
        },
        selected_summary_type() {
            return this.summary_types.find(type => type.name === this.options.summary_type);
        },
        selected_scrape() {
            return this.scrapes.find(scrape => scrape._id === this.options.scrape_id) || null;
        }
    },
    async mounted() {
        this.loading_balance = true;
        this.loading_transcriptions = true;

        if (this.$route.query.scrape_id)
            this.options.scrape_id = this.$route.query.scrape_id;

        await Promise.all([
            this.$store.dispatch('fetchScrapes', {
                limit: 100,
                page: 1,
                status: 'completed'
            }),
            this.$store.dispatch('fetchTranscriptionsBalance'),
            this.$store.dispatch('fetchTranscriptions', {
                limit: this.limit,
                page: this.page,
                status: this.current_filter
            })
        ]);
        this.loading_balance = false;
        this.loading_transcriptions = false;
        this.$nextTick(() => {
            initFlowbite();
        });
    },
    watch: {
        current_filter: {
            handler: async function (val) {
                this.loading_transcriptions = true;
                await this.$store.dispatch('fetchTranscriptions', {
                    limit: this.limit,
                    page: this.page,
                    status: val
                });
                this.loading_transcriptions = false;

                this.$nextTick(() => {
                    initFlowbite();
                });
            },
            immediate: true
        },
        scrapes: {
            handler: function (val) {
                this.$nextTick(() => {
                    initFlowbite();
                });
            },
            immediate: true
        }
    },
}
</script>
