export default {
    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    },
    formatDuration(seconds) {
        seconds = Math.floor(seconds);

        const units = [
            { label: 'y', seconds: 31536000 }, // 60 * 60 * 24 * 365
            { label: 'mo', seconds: 2592000 }, // 60 * 60 * 24 * 30
            { label: 'd', seconds: 86400 }, // 60 * 60 * 24
            { label: 'h', seconds: 3600 }, // 60 * 60
            { label: 'm', seconds: 60 },
            { label: 's', seconds: 1 }
        ];

        const result = [];
        let isTimeComponentDisplayed = false;

        for (const unit of units) {
            const quotient = Math.floor(seconds / unit.seconds);
            if (quotient > 0 || isTimeComponentDisplayed || unit.label === 's') {
                result.push(`${this.pad(quotient)}${unit.label}`);
                seconds -= quotient * unit.seconds;
                isTimeComponentDisplayed = true;
            }
        }

        return result.join(' ');
    },
    pad(num) {
        return num.toString().padStart(2, '0');
    },
    formatTime(time) {
        const date = new Date(time);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
    },
    formatTimezone(time) {
        const date = new Date(time);
        const timezoneName = Intl.DateTimeFormat('en-US', { timeZoneName: 'long', timeZone: date.getTimezoneOffset() === 0 ? 'UTC' : undefined }).resolvedOptions().timeZone || 'UTC';
        return timezoneName;
    },
    formatNumber(number, options = {}, locale = 'en-US') {
        const intl = new Intl.NumberFormat(locale, {
            style: 'decimal',
            maximumFractionDigits: options.maximumFractionDigits || 2,
            minimumFractionDigits: options.minimumFractionDigits || 0,
            ...options
        });

        return intl.format(number);
    },
    formatPrice(price, options = {}, locale = 'en-US') {
        return this.formatNumber(price, {
            style: 'currency',
            currency: options.currency || 'USD',
            ...options
        }, locale);
    },
    capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    },
    formatDate(date) {
        return this.capitalize(new Date(date).toLocaleDateString(undefined, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        }));
    },
    fomartShortDate(date) {
        const d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}`;
    },
    formatDateMonthLetters(date) {
        const d = new Date(date);
        return `${d.getDate()} ${d.toLocaleString('default', { month: 'long' })}`;
    },
    formatShortTime(date) {
        const d = new Date(date);
        return `${this.pad(d.getHours())}:${this.pad(d.getMinutes())}`;
    },
    formatShortDateAndTime(date) {
        return `${this.fomartShortDate(date)}, ${this.formatShortTime(date)}`;
    },
    truncate(str, length) {
        return str.length > length ? str.substring(0, length) + '...' : str;
    },
    randomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
}