<template>
    <div class="sticky bottom-0 right-0 left-0 items-center w-full p-1">
        <div class="relative w-full h-full justify-between flex-row flex" v-if="options">
            <div class="flex items-center sm:mb-0 flex-row" v-if="show_text_result">
                <div class="text-sm font-normal text-gray-500 dark:text-gray-400 ps-2.5">
                    Showing <span class="font-semibold text-gray-900 dark:text-white">
                        {{ options.currentPage }} - {{ options.currentPage + options.perPage - 1 }}</span> of <span
                        class="font-semibold text-gray-900 dark:text-white">{{ formatNumber(options.totalItems)
                        }}</span>
                    results
                </div>
            </div>
            <nav class="absolute left-0 right-0 top-0 bottom-0 flex flex-row justify-center">
                <ul class="flex items-center -space-x-px h-8 text-sm">
                    <li>
                        <button href="#" @click.prevent="decrementPage(options.currentPage)"
                            :class="{ 'opacity-40': options.currentPage === 1, 'dark:hover:bg-gray-700/50 hover:bg-gray-100': options.currentPage != 1, 'bg-white dark:bg-gray-800': options.currentPage === 1 }"
                            :disabled="options.currentPage === 1 || options.totalItems == 0"
                            class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500  border border-e-0 border-gray-300 rounded-s-lg  hover:text-gray-700  dark:border-gray-700 dark:text-gray-400 dark:bg-gray-800 dark:hover:text-white">
                            <span class="sr-only">Previous</span>
                            <svg class="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" d="M5 1 1 5l4 4" />
                            </svg>
                        </button>
                    </li>
                    <li v-for="(p, index) in pages" :key="index">
                        <a v-if="p !== '...'" href="#" @click.prevent="goToPage(p)"
                            class="!no-underline flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white"
                            :class="{
                                'bg-gray-100 text-gray-700 dark:bg-gray-900/50 dark:text-white dark:hover:bg-gray-900/70': p === options.currentPage,
                                'dark:bg-gray-800 dark:hover:bg-gray-700/50': p != options.currentPage,
                            }">
                            {{ p }}
                        </a>
                        <button v-else @click.prevent="promptPageNumber"
                            class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 border border-gray-300 cursor-pointer dark:hover:bg-gray-700/50 hover:text-gray-700 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white">
                            {{ p }}
                        </button>
                    </li>
                    <li>
                        <button href="#" @click.prevent="incrementPage(options.currentPage)"
                            :disabled="options.currentPage == Math.ceil(options.totalItems / options.perPage) || options.totalItems == 0"
                            :class="{
                                'opacity-40 dark:bg-gray-800 bg-white dark:bg-gray-800': options.currentPage === Math.ceil(options.totalItems / options.perPage) || options.totalItems == 0,
                                'dark:hover:bg-gray-700/50 hover:bg-gray-100 dark:bg-gray-800': options.currentPage != Math.ceil(options.totalItems / options.perPage),
                            }"
                            class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 border border-gray-300 rounded-e-lg  hover:text-gray-700 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white">
                            <span class="sr-only">Next</span>
                            <svg class="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" d="m1 9 4-4-4-4" />
                            </svg>
                        </button>
                    </li>
                </ul>
            </nav>
            <div class="flex flex-row items-center justify-end z-50" v-if="show_per_page">
                <select name="perPage" id="perPage" @change="goToPage(1)"
                    class="form-select w-20  py-1.5 text-sm text-gray-500 dark:text-gray-400 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 dark:hover:text-white"
                    v-model="options.perPage">
                    <option v-for="perPage in perPage_options" :key="perPage" :value="perPage"
                        :selected="perPage === options.perPage">
                        {{ perPage }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>
<script>
import Utils from '../utils';

export default {
    name: 'Pagination',
    data() {
        return {
            perPage_options: [10, 20, 50, 100],
        }
    },
    props: {
        options: {
            type: Object,
            default: () => ({}),
        },
        show_text_result: {
            type: Boolean,
            default: true,
        },
        show_per_page: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        ...Utils,
        incrementPage(currentPage) {
            this.$emit('update:options', {
                limit: this.options.perPage,
                page: currentPage + 1
            });
        },
        decrementPage(currentPage) {
            this.$emit('update:options', {
                limit: this.options.perPage,
                page: currentPage - 1
            });
        },
        goToPage(page) {
            this.$emit('update:options', {
                limit: this.options.perPage,
                page
            });
        },
        promptPageNumber() {
            let userInput = prompt('Enter the page number you want to navigate to:');
            let pageNumber = parseInt(userInput);

            if (
                !isNaN(pageNumber) &&
                pageNumber >= 1 &&
                pageNumber <= Math.ceil(this.options.totalItems / this.options.perPage)
            ) {
                this.goToPage(pageNumber);
            } else {
                alert('Please enter a valid page number.');
            }
        },
    },
    computed: {
        pages() {
            let currentPage = this.options.currentPage || 1;
            const totalPages = Math.ceil(this.options.totalItems / this.options.perPage) || 1;
            const pages = [];
            const maxVisiblePages = 5; // Maximum number of page numbers to display excluding '...'

            if (totalPages <= maxVisiblePages) {
                // Display all pages
                for (let i = 1; i <= totalPages; i++) {
                    pages.push(i);
                }
            } else {
                // Always show first page
                pages.push(1);

                if (currentPage > 3) {
                    pages.push('...');
                }

                // Determine the range of page numbers to display around currentPage
                let startPage = Math.max(2, currentPage - 1);
                let endPage = Math.min(totalPages - 1, currentPage + 1);

                // Adjust pages if currentPage is near the start
                if (currentPage <= 3) {
                    startPage = 2;
                    endPage = 4;
                }

                // Adjust pages if currentPage is near the end
                if (currentPage >= totalPages - 2) {
                    startPage = totalPages - 3;
                    endPage = totalPages - 1;
                }

                // Add the range of pages
                for (let i = startPage; i <= endPage; i++) {
                    pages.push(i);
                }

                if (currentPage < totalPages - 2) {
                    pages.push('...');
                }

                // Always show last page
                pages.push(totalPages);
            }

            return pages;
        }
    }
}
</script>